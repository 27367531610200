import React from "react";

import Layout from "../../components/layout";
import { Seo } from "../../components";
// import AboutProfile from "../../components/about-profile";

// import WithService from "../../service/withService";

// const Chaplain = ({ loading, response, error }) => {
//   return <AboutProfile loading={loading} response={response} />;
// };

const BishopOffice = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Bishop Office" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">Bishop's Office</h3>
              {/* <div className="row">
                <WithService endpoint="GetBishopChaplian">
                  <Chaplain />
                </WithService>
              </div> */}
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="about-image-wrapper about-image-wrapper_extra-small">
                    <img src='https://hm-mkd-public.s3.ap-south-1.amazonaws.com/images/7bb4d829-b6a8-4d3f-8ec1-746466f459db.png' className="w-100"></img>
                  </div>
                </div>
                <div className="col-12 col-md-9">
                  <p>
                    <b>Rev. Shery Varghese</b>
                    <br />
                    Bishop’s Chaplain <br />
                    Norton Clergy Home
                    <br />
                    CSI Diocesan Office Compound, Cathedral Road, <br />
                    Kottayam- 686 001 <br />
                    Phone: 0481-2566536 (O)
                    <br />
                    Mob: 9447704082
                    <br />
                    E-mail:
                    <a
                      href="mailto:sheryv7@yahoo.co.in"
                      className="about-mail"
                    >
                      sheryv7@yahoo.co.in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BishopOffice;
